import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'rgb'
})
export class RgbPipe implements PipeTransform {

  transform(card: any | 0, prefix: string): string | null {
    if (!card) {
      return null
    }
    return `${prefix}: rgb(${card.r}, ${card.g}, ${card.b})`
  }

}
