import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PopupService } from '@services/popup.service'

export type Mood = 'happy' | 'calm' | 'sad' | 'done' | 'compress'

@Component({
  selector: 'inka-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Input() message: string = 'Do you want to stop bladder training?'
  @Input() tense: boolean = false
  @Input() buttons: boolean = true

  @Output() accept: EventEmitter<any> = new EventEmitter<any>()
  @Output() decline: EventEmitter<any> = new EventEmitter<any>()

  constructor(public popup: PopupService) {
  }

  ngOnInit(): void {
  }
}
