import { Component, ElementRef, HostListener, Injector, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { DIALOG_STATE, DialogContent, DialogService } from '@shared/services/dialog.service';

@Component({
  selector: 'inka-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  @ViewChild('overlay', { static: false })
  public overlayElement: ElementRef | undefined;

  constructor(
    public dialogService: DialogService) { }

  onOverlayClick(): void {
    this.dialogService.close();
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}

@Component({
  selector: 'inka-dialog-container',
  template: '<ng-container #container></ng-container>'
})
export class DialogContainerComponent {
  @ViewChild('container', { read: ViewContainerRef, static: true }) public container!: ViewContainerRef;

  @Input()
  public set content(content: DialogContent | null) {
    this.container.clear();
    if (content?.component) {
      const injector = Injector.create({
        providers: [{
          provide: DIALOG_STATE,
          useValue: content.state
        }],
        parent: this.injector
      });
      this.container.createComponent(content.component, { injector: injector });
    }
  }

  constructor(private injector: Injector) { }
}
