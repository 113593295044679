<div [class.hidden]="(timer.hidden$ | async) || timer.extra" class="timer active">
  <div *ngIf="timer.timers.total as total" [class.complete]="total.done" class="time">
    <p *ngIf="!total.done; else done">
      {{ total.stopWatch$ | async }} {{'result.of' | translate}} {{total.max | toMinutes | date:'m'}} {{'result.min' | translate}}
    </p>

    <ng-template #done>
      <p *ngIf="timer.timers.limit as limit">
        {{ limit.stopWatch$ | async }} {{'result.of' | translate}} {{limit.max | toMinutes | date:'m'}} {{'result.min' | translate}}
        <svg fill="none" height="11" viewBox="0 0 12 11" width="12" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.9962 1.76472C11.9759 1.54868 11.876 1.35376 11.7145 1.21569L10.5088 0.184218C10.1763 -0.100045 9.68389 -0.0496891 9.41075 0.296299L4.3405 6.71819L2.30366 4.90135C1.97667 4.6114 1.48346 4.64958 1.20329 4.98988L0.187997 6.22358C0.0522071 6.38764 -0.013347 6.59799 0.00226094 6.81403C0.0178689 7.03007 0.114639 7.22743 0.272279 7.36875L4.12433 10.8043C4.26714 10.9318 4.44507 11 4.63081 11C4.65422 11 4.67685 10.9992 4.70026 10.9968C4.91097 10.9773 5.10061 10.8733 5.23484 10.7036L11.823 2.35923C11.9548 2.19192 12.0165 1.97994 11.9962 1.76472Z"
            fill="#46C783"/>
        </svg>
      </p>
    </ng-template>
  </div>
</div>
