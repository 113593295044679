import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { InfoService } from '@services/info.service'
import { GameName } from '@services/lobby.service'
import { ResultService } from '@services/result.service'


@Component({
  selector: 'inka-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {
  @Input() id: GameName = 'main'

  @Input() list: number[] = []
  @Input() type: undefined | 'game' | 'goal';
  @Input() item: undefined | 'rule' | 'instruction';
  @Input() option: boolean = false;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>()

  dontShow: boolean = false

  constructor(private result: ResultService,
              public info: InfoService) {
  }

  ngOnInit(): void {
    this.getStatus(this.id)
    if (this.dontShow) {
      return
    }
    this.show()
  }

  ngOnDestroy() {
    this.hide(this.id)
  }

  getStatus(id: GameName) {
    const status = this.info.getStatus(id)
    this.dontShow = status.dontShow
  }

  setStatus() {
    const {id, dontShow} = this
    localStorage.setItem(id, JSON.stringify({dontShow}))
  }

  show() {
    this.info.visible$.next(true)
  }

  hide(id: GameName) {
    this.info.visible$.next(false)
    this.result.setInfo(id, false)
    this.setStatus()
    this.onClose.emit()
  }

  next() {
    this.hide(this.id)
  }
}
