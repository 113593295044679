import { Injectable } from '@angular/core'
import { PopupService } from '@services/popup.service'
import { BehaviorSubject, Subject } from 'rxjs'
import { GameName } from './lobby.service'

class Local {
  name!: GameName
  suffix: string = ''

  constructor(name: GameName, suffix: string) {
    this.name = name
    this.suffix = suffix
  }

  load() {
    const key: string = this.name + this.suffix
    const total = JSON.parse(localStorage.getItem(key) || '0')
    localStorage.removeItem(key)
    return total || 0
  }

  save(value: any) {
    localStorage.setItem(this.name + this.suffix, JSON.stringify(value))
  }
}

class Time extends Local {
  initial: number = 0

  constructor(name: GameName) {
    super(name, '_time')
    this.total = this.load()
  }

  private _total: number = 0

  get total(): number {
    return this._total | 0
  }

  set total(total: number) {
    this._total = total
  }

  start(initial: number = Date.now()) {
    this.initial = initial
  }

  end() {
    if (this.initial) {
      this.total += Date.now() - this.initial
    } else {
      this.total = 0
    }
    this.save(this.total)
  }
}

export class Score {
  total: number = 0

  increment() {
    this.total += 1
  }
}

class GameScore {
  name: GameName
  score: Score = new Score()
  time!: Time
  info: boolean = true

  constructor(name: GameName) {
    this.name = name
    this.time = new Time(name)
  }
}

interface SimpleMap { [key: string]: GameScore }

class Games implements SimpleMap {
  [key: string]: GameScore

  memoryCards: GameScore = new GameScore('memoryCards')
  findNumber: GameScore = new GameScore('findNumber')
  fifteen: GameScore = new GameScore('fifteen')
  mascot: GameScore = new GameScore('mascot')
  meditation: GameScore = new GameScore('meditation')
  quiz: GameScore = new GameScore('quiz')

  waves: GameScore = new GameScore('waves')
  particles: GameScore = new GameScore('particles')
  hole: GameScore = new GameScore('hole')

  tension: GameScore = new GameScore('tension')
  main: GameScore = new GameScore('main')
}

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  visible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  answer!: Subject<boolean>
  success: boolean = false

  manualExit: boolean = false

  games: Games = new Games()

  constructor(public popup: PopupService) {
  }

  getInfo(name: GameName) {
    return this.games[name].info
  }

  setInfo(name: GameName, state: boolean = false) {
    this.games[name].info = state
  }

  incrementScore(name: GameName) {
    this.games[name].score.increment()
  }

  hide(answer: boolean) {
    this.answer.next(answer)
    this.visible$.next(false)
  }

  show() {
    this.visible$.next(true)
    this.answer = new Subject<boolean>()
    return this.answer.asObservable()
  }

  back(force: boolean = false) {
    this.popup.back(force)
  }

  exit() {
    this.manualExit = true
    this.show()
      .subscribe(leave => {
        this.manualExit = false
        leave
          ? this.popup.exit()
          : this.popup.back(true)
      })
  }
}
