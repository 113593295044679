// Modules
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { BackComponent } from '@component/back/back.component'
import { ExitComponent } from '@component/exit/exit.component'
// Components
import { GameComponent } from '@component/game/game.component'
import { HeaderComponent } from '@component/header/header.component'
import { InfoComponent } from '@component/info/info.component'
import { MuteComponent } from '@component/mute/mute.component'
import { PopupComponent } from '@component/popup/popup.component'
import { ResultComponent } from '@component/result/result.component'
import { ScoreComponent } from '@component/score/score.component'
import { TimeComponent } from '@component/time/time.component'
import { SettingsComponent } from './component/settings/settings.component'
import { DialogComponent, DialogContainerComponent } from './component/dialog/dialog.component'
import { ToMinutesPipe } from '@shared/pipes/toMinutes.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { GridsterModule } from 'angular-gridster2'
import { CountdownModule } from 'ngx-countdown'
// Utils
import { GridDirective } from './directives/grid.directive'
import { RepeatPipe } from './pipes/repeat.pipe'
import { RgbPipe } from './pipes/rgb.pipe';
import { LetDirective } from './directives/let.directive';

const sharedComponents = [
  GameComponent,
  // Helpers
  BackComponent,
  MuteComponent,
  HeaderComponent,
  ScoreComponent,
  TimeComponent,
  PopupComponent,
  ResultComponent,
  InfoComponent,
  SettingsComponent,
  DialogComponent,
  // Exit
  ExitComponent
]

const sharedDirectives = [
  GridDirective,
  LetDirective
]

const sharedPipes = [
  RgbPipe,
  RepeatPipe,
  ToMinutesPipe
]

const sharedModules = [
  GridsterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  HttpClientModule,
  RouterModule,
  CountdownModule
]

@NgModule({
  declarations: [
    ...sharedComponents,
    ...sharedDirectives,
    ...sharedPipes,
    DialogContainerComponent,
  ],
  imports: [
    CommonModule,
    ...sharedModules
  ],
  exports: [
    ...sharedComponents,
    ...sharedDirectives,
    ...sharedPipes,
    ...sharedModules
  ]
})
export class SharedModule {
}
