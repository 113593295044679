import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'inka-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() backButton: boolean = true
  @Input() muteButton: boolean = true

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  back() {
    this.router.navigate(['/', 'games'])
  }
}
