<div *ngIf="popup.visible$ | async" class="popup">

  <div [class.success]="popup.success" class="popup-content">
    <div class="filler"></div>

    <div class="popup-message {{popup.mood | async}}">
      <div class="title">
        {{'button.question.' + popup.title | translate : popup.translateParams}}
      </div>
      <ng-content></ng-content>

      <svg height="22" width="30">
        <polygon fill="#FFFFFF" points="3,0 27,0 15,16" stroke="#FFFFFF" stroke-linejoin="round" stroke-width="5" />
      </svg>
    </div>

    <div class="filler"></div>

    <div *ngIf="buttons; else noButtons" class="actions">
      <button (click)="popup.close(false)" *ngIf="popup.actionAccept | async" class="accept">
        {{'button.action.' + (popup.actionAccept | async) | translate}}
      </button>
      <button (click)="popup.close(true)" *ngIf="popup.actionDecline | async" class="decline">
        {{'button.action.' + (popup.actionDecline | async) | translate}}
      </button>
    </div>
    <ng-template #noButtons>
      <div class="filler actions"></div>
    </ng-template>
  </div>
</div>
