import { Component } from '@angular/core'
import { TimerService } from '@services/timer.service'

@Component({
  selector: 'inka-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent {

  constructor(public timer: TimerService) {
  }

  ngOnInit() {
  }
}

