import { Injectable } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { InfoService } from '@services/info.service'
import { GameName } from '@services/lobby.service'
import { MediaService } from '@services/media.service'
import { PopupService } from '@services/popup.service'
import { ResultService } from '@services/result.service'
import { format } from 'date-fns'
import { BehaviorSubject, merge, NEVER, Observable, Observer, Subject, timer } from 'rxjs'
import { filter, map, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators'

export const timeSpent = 'time_spent'
export const maxTime = 'max_time'

export type TimerKey = 'game' | 'total' | 'limit' | GameName

export const INITIAL = 300;
export const MAX = 1800;

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  hidden$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  current: TimerKey[] = []

  ending: boolean = false
  extra: boolean = false

  timers: Timers = new Timers()

  constructor(public result: ResultService,
              private route: ActivatedRoute,
              private popup: PopupService,
              private info: InfoService,
              private media: MediaService,
              private router: Router) {
    this.init()
  }

  init() {
    const {timers} = this

    this.initRouterEvents()
    this.setMax(timers)

    timers.init()

    this.subscribe(timers)
  }

  create() {
    return this.timers = new Timers()
  }

  subscribe(timers: Timers) {
    // Pause on any popup visible
    merge(
      this.info.visible$,
      this.result.visible$,
      this.popup.visible$
    )
      .subscribe(visible => {
        visible
          ? timers.pauseAll()
          : this.hidden$.value
            ? timers.pauseAll()
            : timers.resume(...this.current)
      })

    // Pause on time component hidden
    this.hidden$
      .subscribe((next) => {
        next
          ? timers.pauseAll()
          : this.info.visible$.value ||
          this.result.visible$.value ||
          this.popup.visible$.value
            ? timers.pauseAll()
            : timers.resume(...this.current)
      })

    //
    timers.total.timer$
      .pipe(
        map(milliseconds => milliseconds / 1000)
      )
      .subscribe(
        seconds => {
          const {max} = timers.total
          this.detectEnd(seconds, max)
          timers.total.calculatePercent()
        },
        e => {
        },
        () => {
          if (timers.total.done) {
            timers.total.calculatePercent()
            return
          }
        }
      )
    timers.limit.timer$
      .pipe(
        map(milliseconds => milliseconds / 1000)
      )
      .subscribe(
        seconds => {
          const {max} = timers.limit
          this.detectEnd(seconds, max)
          timers.total.calculatePercent()
        },
        e => {
        },
        () => {
          console.log({...timers.limit})
          if (timers.limit.done) {
            this.showResult()
          }
        }
      )
  }

  detectEnd(seconds: number, max: number) {
    if (seconds >= max - 10) {
      if (!this.ending) {
        this.media.play('clock')
        this.ending = true
      }
      this.media.vibrate([100])
    }
    if (seconds === max) {
      this.media.vibrate([1000])
      this.media.stop('clock')
    }
  }

  initRouterEvents() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd)
      )
      .subscribe((end) => {
        const {urlAfterRedirects} = end
        this.setCurrent(urlAfterRedirects)
        this.setHidden(urlAfterRedirects)
      })
    this.setHidden(location.pathname)
  }

  setMax(timers: Timers) {
    const {time} = this.route.snapshot.queryParams
    time
      ? timers.setMax(+time)
      : timers.setMax(INITIAL)
  }

  setHidden(url: string) {
    const game: boolean = url.includes('/game')
    const games: boolean = url.includes('/games')

    const meditation = url.includes('waves')
      || url.includes('hole')
      || url.includes('particles')

    const hidden: boolean = !((meditation || game) && !games)

    this.extra = url.includes('muscle-tension') || meditation

    this.hidden$.next(hidden)
  }

  setCurrent(url: string) {
    if (url.includes('/games')) {
      this.current = []
      return
    }
    this.current = url.split('/').splice(1, 1) as TimerKey[]
  }

  showResult() {
    this.result.show()
      .subscribe(another => {
        another
          ? this.another()
          : this.continue()
      })
  }

  another() {
    this.router.navigate(['/', 'games'])
      .then(() => {
        this.clear()
        this.result.visible$.next(false)
      })
  }

  leave() {
    const total = this.timers.totalTimes()
    this.router.navigate(
      ['/', 'exit'],
      {
        queryParams: {...total, exit: true}
      }
    )
      .then(() => {
        this.result.visible$.next(false)
        this.timers.unsubscribeAll()
      })
  }

  continue() {

  }

  clear() {
    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams: null
      }
    )
    return this
  }
}

class Timer {
  key!: TimerKey

  pause$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  startStop$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  stopWatch$: BehaviorSubject<string> = new BehaviorSubject<string>('0')

  timer$!: Observable<number>

  end$: Subject<any> = new Subject<any>()

  max: number = 0
  done: boolean = false

  constructor(key: TimerKey) {
    if (!key) {
      throw new Error('Key required for Timer')
    }
    this.key = key
  }

  get current() {
    return this.load(timeSpent)
  }

  increment(shift: number) {
    const local = this.load(timeSpent)
    const shifted = local + shift
    this.saveTime(shifted)
    // this.timer$.next(shifted)
  }

  init() {
    const {key} = this
    const startWith: number = this.startWith()
    const max: number = key === 'total'
      ? this.load(maxTime, false)
      : MAX
    const timeFormat: string = key === 'total' ? 'mm:ss' : 'm'

    this.timer$ = this.timerWithPause(this.startStop$, this.pause$, startWith)
      .pipe(
        takeWhile(seconds => seconds <= max),
        tap((seconds) => {
          this.saveTime(seconds)
        }),
        map(seconds => {
          return seconds * 1000
        })
      )
    this.timer$
      .pipe(
        takeUntil(this.end$)
      )
      .subscribe({
        next: value => this.stopWatch$.next(format(value, timeFormat)),
        complete: () => {
          this.done = true
        }
      })
    return this
  }

  startWith(): number {
    const {current} = this
    return current && current > 0 ? current : 0
  }

  timerWithPause(
    startStop: Observable<boolean>,
    pause: Observable<boolean>,
    startWith: number,
    fps: number = 1
  ): Observable<number> {
    return new Observable((obs: Observer<number>) => {
      let i = startWith || 0
      let ticker = startStop.pipe(
        switchMap(start => {
          if (start) return timer(0, 1000 / fps).pipe(map(_ => i++))
          i = 0
          return NEVER
        })
      )

      let p = pause.pipe(switchMap(paused => (paused ? NEVER : ticker)))
      return p.subscribe({
        next: val => obs.next(val),
        error: err => obs.error(err),
        complete: () => obs.complete()
      })
    })
  }

  saveTime(seconds: number | string) {
    localStorage.setItem(`${this.key}-${timeSpent}`, `${seconds}`)
  }

  load(key: string, prefix: boolean = true) {
    const local = localStorage.getItem(prefix ? `${this.key}-${key}` : key)
    return local ? +local : 0
  }

  clear(key: string) {
    localStorage.removeItem(`${this.key}-${key}`)
  }

  handleStart() {
    if (this.startStop$.value) {
      if (this.pause$.value) {
        this.resume()
      } else {
        this.pause()
      }
    } else {
      this.start()
    }
  }

  start() {
    this.startStop$.next(true)
    return this
  }

  stop() {
    this.startStop$.next(false)
    return this
  }

  pause() {
    this.pause$.next(true)
    return this
  }

  resume() {
    const {value} = this.startStop$
    if (!value) {
      this.start()
    }
    this.pause$.next(false)
    return this
  }

  reset() {
    this.stop().resume()
    this.saveTime(0)
    this.stopWatch$.next('0')
    return this
  }

  unsubscribe() {
    this.end$.next(undefined)
    this.end$.complete()

    this.startStop$.complete()
    this.pause$.complete()

    this.clear(timeSpent)
    this.clear(maxTime)
  }
}

class TotalTimer extends Timer {
  done: boolean = false
  percent: number = 0

  constructor() {
    super('total')
  }

  calculatePercent() {
    const max = Math.floor(this.load(maxTime, false) / 60);
    const spent = Math.floor(this.load(timeSpent) / 60);
    return this.percent = spent / max * 100 | 0
  }
}

class LimitTimer extends Timer {
  done: boolean = false
  max: number = MAX

  constructor() {
    super('limit')
  }
}

export class Timers {
  names: TimerKey[] = [
    // Exercises
    'tension',
    // Games
    'memoryCards',
    'findNumber',
    'fifteen',
    'mascot',
    // Meditations
    'waves',
    'hole',
    'particles'
  ]
  stack: Timer[] = []
  total: TotalTimer = new TotalTimer()
  limit: LimitTimer = new LimitTimer()

  constructor() {
  }

  init() {
    this.stack = this.names.map(key => new Timer(key))
    this.total.init()
    this.limit.init()
  }

  create(key: TimerKey): Timer {
    const exists = this.find(key)
    if (exists) {
      exists.resume()
      return exists
    }
    const timer = new Timer(key)
    this.stack.push(timer)
    return timer
  }

  find(key?: TimerKey): Timer | undefined {
    if (!key) {
      return
    }
    return this.stack.find(timer => timer.key === key)
  }

  setMax(max?: number | string) {
    if (!max) {
      max = INITIAL
    }
    this.total.max = +max
    localStorage.setItem(maxTime, `${max}`)

    this.setLimit()
    return this
  }

  setLimit() {
    const {limit} = this
    limit.max = MAX
    localStorage.setItem(`${limit.key}-${maxTime}`, `${MAX}`)
  }

  unsubscribeAll() {
    this.stack.forEach(timer => {
      timer.unsubscribe()
    })
    this.total.unsubscribe()
    this.limit.unsubscribe()
    return this
  }

  start(key?: TimerKey) {
    const timer = this.find(key)
    if (timer) {
      timer.start()
    }
    this.total.start()
    this.limit.start()
    return this
  }

  pause(key?: TimerKey) {
    const timer = this.find(key)
    if (timer) {
      timer.pause()
    }
    this.total.pause()
    this.limit.pause()
    return this
  }

  pauseAll() {
    this.stack.forEach(timer => timer.pause())
    this.total.pause()
    this.limit.pause()
  }

  resume(key?: TimerKey) {
    const timer = this.find(key)
    if (timer) {
      timer.resume()
    }
    this.total.resume()
    this.limit.resume()
    return this
  }

  increment(key: TimerKey, shift: number = 10) {
    const timer = this.find(key)
    if (timer) {
      timer.increment(shift)
    }
    this.total.increment(shift)
    this.total.calculatePercent()
    this.limit.increment(shift)
  }

  totalTimes() {
    const times: any = {}
    this.stack.forEach(({current, key}) => {
      if (current) {
        times[key] = current
      }
    })
    times.total = this.total.current
    return times
  }
}
