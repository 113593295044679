import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'toMinutes',
  pure: false
})
export class ToMinutesPipe implements PipeTransform {

  transform(minutes: number): number {
    if (!minutes) {
      return 0
    }
    return minutes * 1000
  }

}
