import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, interval } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'inka-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.scss']
})
export class ExitComponent implements OnInit, OnDestroy {

  private clickSubject = new Subject<void>();

  private subscription?: Subscription;

  @HostListener('click', ['$event.target'])
  onClick() {
    this.clickSubject.next(void 0);
  }

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.subscription = interval(2000)
      .pipe(
        take(1),
        switchMap(() => this.clickSubject),
        take(1))
      .subscribe(() => this.router.navigate(['/', 'games']));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
