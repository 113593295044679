import { Injectable } from '@angular/core'
import { Sound, SoundService } from './sound.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  mute: boolean = true
  disable: boolean = false
  cache: { [key: string]: HTMLAudioElement } = {}

  constructor(private readonly soundService: SoundService) {}

  play(sound: Sound) {
    if (this.mute) {
      return
    }
    this.stop(sound)
    this.soundService.play(sound);
  }

  stop(sound: Sound) {
    this.soundService.stop(sound);
  }

  vibrate(pattern: number[] = [0]) {
    const canVibrate = 'vibrate' in navigator
    if (this.disable || !canVibrate) {
      return
    }
    navigator.vibrate(pattern)
  }

  meditationStart() {
    this.vibrate([0])
  }

  meditationEnd() {
    // this.play('bell')
    this.vibrate(
      [50, 50, 100, 100, 420, 333, 666]
    )
  }

  toggleMute() {
    this.mute = !this.mute
    for (let key in this.cache) {
      const audio = this.cache[key]
      audio.pause()
      audio.currentTime = 0
    }
  }
}

