import { LocationStrategy } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { ResultService } from '@services/result.service'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'inka-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  games: boolean = false
  destroyed$: Subject<any> = new Subject<any>()

  constructor(private location: LocationStrategy,
              private router: Router,
              private result: ResultService) {
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd), takeUntil(this.destroyed$))
      .subscribe((e) => {
        this.games = e.urlAfterRedirects.includes('/games')
        document.scrollingElement?.scrollTo({ top: 0 })
      })

    this.location.onPopState((e) => {
      if (this.games) {
        // this.router.navigate(['/', 'games'])
        this.result.exit()
      }
      return false
    })
  }

  ngOnDestroy() {
    this.destroyed$.next(undefined)
    this.destroyed$.complete()
  }

}
