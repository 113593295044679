import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'inka-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>()

  constructor() {
  }

  emit() {
    this.onClick.emit()
  }
}
