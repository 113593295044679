<header>
  <div class="instruction">
    <span>
      <inka-back (back)="back()" *ngIf="backButton" [color]="muteButton ? '#111111' : '#0BAEEB'"></inka-back>
    </span>
    <span class="settings">
      <inka-time></inka-time>
    </span>
    <div class="content">
      <inka-mute *ngIf="muteButton"></inka-mute>
      <ng-content></ng-content>
    </div>
  </div>
</header>
