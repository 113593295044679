import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'repeat'
})
export class RepeatPipe implements PipeTransform {

  transform(times: number, value: string = '1fr'): string {
    if (!times) {
      return ''
    }
    return `repeat(${times}, ${value})`
  }

}
