import { Component } from '@angular/core'

@Component({
  selector: 'inka-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent {
  constructor() {
  }
}
