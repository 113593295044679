import { Injectable } from '@angular/core'
import { GameName } from '@services/lobby.service'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  visible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor() {
  }

  getStatus(name: GameName): { dontShow: boolean } {
    const local = localStorage.getItem(name)
    return local ? JSON.parse(local) : {dontShow: false}
  }
}
