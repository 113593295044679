import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core'

@Directive({
  selector: '[inkaGrid]'
})
export class GridDirective {
  @HostBinding('style.grid-template-columns')
  @Input() columns: string = ''
  @HostBinding('style.grid-template-rows')
  @Input() rows: string = ''
  @HostBinding('style.height')
  @Input() height: string = ''
  @HostBinding('style.grid-gap')
  @Input() gap: string = '16px'

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {
    this.renderer.setStyle(this.el.nativeElement, 'display', 'grid')
  }


}
