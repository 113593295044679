import { Injectable, InjectionToken, Type } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

export const DIALOG_STATE = new InjectionToken("DIALOG_STATE");

export interface DialogContent {
  component: Type<unknown>,
  state: unknown
};

type NullableComponent = DialogContent | null;

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogContentSubject: BehaviorSubject<NullableComponent> = new BehaviorSubject<NullableComponent>(null);
  private resultSubject: Subject<any> = new Subject()

  public get dialogContent$(): Observable<NullableComponent> {
    return this.dialogContentSubject.asObservable();
  }

  constructor() { }

  public show<T>(component: Type<unknown>, state?: unknown): Observable<T> {
    this.dialogContentSubject.next({component, state});
    return this.resultSubject.pipe(first());
  }

  public close<T>(result?: T) {
    this.resultSubject.next(result);
    this.dialogContentSubject.next(null);
  }
}
