import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

class LetContext<T = unknown> {
  public $implicit: T = null!;
  public inkaLet: T = null!;
}

@Directive({
  selector: '[inkaLet]'
})
export class LetDirective<T = unknown> {
  private context: LetContext<T> = new LetContext<T>();
  private viewRef: EmbeddedViewRef<LetContext<T>> | null = null;

  @Input('inkaLet')
  set let(value: T) {
    this.context.$implicit = this.context.inkaLet = value;
    this.viewRef ??= this.viewContainer.createEmbeddedView(this.templateRef, this.context);
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<LetContext<T>>
  ) { }
}
