import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'inka-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent implements OnInit {
  @Input() absolute: boolean = false
  @Output() back: EventEmitter<any> = new EventEmitter<any>()
  @Input() color: string = '#111111'

  constructor() {
  }

  ngOnInit(): void {
  }

  emit() {
    this.back.emit()
  }
}
