import { Component } from '@angular/core'
import { MediaService } from '@services/media.service'

@Component({
  selector: 'inka-mute',
  templateUrl: './mute.component.html',
  styleUrls: ['./mute.component.scss']
})
export class MuteComponent {

  constructor(public media: MediaService) {
  }
}
