<div (click)="show()" class="info">i</div>

<div [class.visible]="info.visible$ | async" class="information">
  <div class="information-content">
    <h3>
      {{'info.' + id + '.header' | translate}}
    </h3>

    <ng-container [ngSwitch]="type">
      <div *ngSwitchCase="'game'" class="options">
        <h4>
          {{'info.label.types' | translate}}:
        </h4>
        <div class="answer">
          {{'info.' + id + '.type' | translate}}
        </div>
      </div>

      <div *ngSwitchCase="'goal'" class="options">
        <h4>
          {{'info.label.goals' | translate}}:
        </h4>
        <div class="answer">
          {{'info.' + id + '.goal' | translate}}
        </div>
      </div>
    </ng-container>

    <ng-container [ngSwitch]="item">
      <h4 *ngSwitchCase="'rule'">
        {{'info.label.rules' | translate}}:
      </h4>
      <h4 *ngSwitchCase="'instruction'">
        {{'info.label.instructions' | translate}}:
      </h4>
    </ng-container>

    <ng-container *ngIf="item">
      <p *ngFor="let item of list">
        {{'info.' + id + '.' + item | translate}}
      </p>
    </ng-container>

    <div *ngIf="option" class="options">
      <h4>
        {{'info.label.options' | translate}}:
      </h4>
      <div class="answer">
        {{'info.' + id + '.option' | translate}}
      </div>
    </div>
  </div>

  <div class="actions">
    <div class="control">
      <input (ngModelChange)="dontShow = !dontShow" [(ngModel)]="dontShow" id="showAgain" type="checkbox">

      <label for="showAgain">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <rect *ngIf="!dontShow" fill="#FFFFFF" height="22" rx="3" stroke="#E1E8E8" stroke-width="2" width="22" x="1"
            y="1" />
          <rect *ngIf="dontShow" fill="#079BD1" height="24" rx="4" width="24" />
          <path *ngIf="dontShow"
            d="M17.9962 8.26472C17.9759 8.04868 17.876 7.85376 17.7145 7.71569L16.5088 6.68422C16.1763 6.39996 15.6839 6.45031 15.4107 6.7963L10.3405 13.2182L8.30366 11.4014C7.97667 11.1114 7.48346 11.1496 7.20329 11.4899L6.188 12.7236C6.05221 12.8876 5.98665 13.098 6.00226 13.314C6.01787 13.5301 6.11464 13.7274 6.27228 13.8688L10.1243 17.3043C10.2671 17.4318 10.4451 17.5 10.6308 17.5C10.6542 17.5 10.6768 17.4992 10.7003 17.4968C10.911 17.4773 11.1006 17.3733 11.2348 17.2036L17.823 8.85923C17.9548 8.69192 18.0165 8.47994 17.9962 8.26472V8.26472Z"
            fill="white" />
        </svg>
        <span class="text">
          {{'info.button.again' | translate}}
        </span>
      </label>
    </div>

    <button (click)="next()" [class.accept]="dontShow" class="decline">
      {{'info.button.' + (dontShow ? 'forgot' : 'next') | translate}}
    </button>
  </div>
</div>