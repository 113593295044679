import { Injectable } from '@angular/core';
import { BufferLoader } from './buffer-loader';

const Sounds = [
  'bell',
  'clock',
  'coin',
  'drop-dice',
  'drop',
  'end',
  'first',
  'game-over',
  'hit',
  'pills',
  'second',
  'shuffle',
  'sport',
  'tap',
  'twice',
] as const;

type SoundTuple = typeof Sounds;
export type Sound = SoundTuple[number]

@Injectable({
  providedIn: 'root'
})
export class SoundService {

  private context = new AudioContext();
  private bufferLoader = new BufferLoader(this.context);
  private playingBuffers = new Map<Sound, AudioBufferSourceNode>;
  private buffers: Map<Sound, AudioBuffer> | undefined;

  constructor() {
    this.initialize();
  }

  // todo: initialize via APP_INITIALIZER
  public async initialize(): Promise<void> {
    this.buffers = await this.bufferLoader.load(Sounds.map(sound => sound));
  }

  play(name: Sound): void {
    const buffer = this.buffers?.get(name);
    if (buffer) {
      const source = this.context.createBufferSource();
      source.buffer = buffer;
      source.connect(this.context.destination);
      source.start();
      this.playingBuffers.set(name, source);
    }
  }

  stop(name: Sound): void {
    const source = this.playingBuffers?.get(name);
    if (source) {
      source.stop();
      source.disconnect(this.context.destination);
      this.playingBuffers.delete(name);
    }
  }
}
