import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Mood } from '@component/popup/popup.component'
import { BehaviorSubject, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  visible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)  // false
  title: string = ''
  translateParams: unknown;
  answer: Subject<boolean> = new Subject()
  force: boolean = false

  success: boolean = false
  done: boolean = false

  actionAccept: BehaviorSubject<string> = new BehaviorSubject<string>('more')
  actionDecline: BehaviorSubject<string> = new BehaviorSubject<string>('home')
  mood: BehaviorSubject<Mood> = new BehaviorSubject<Mood>('happy')

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  setAccept(name: string = 'more') {
    this.actionAccept.next(name)
  }

  setDecline(name: string = 'home') {
    this.actionDecline.next(name)
  }

  setMood(name: Mood = 'happy') {
    this.mood.next(name)
  }

  close(answer: boolean) {
    this.answer.next(answer)
    this.visible$.next(false)
    this.reset()
  }

  reset() {
    this.title = ''
    this.translateParams = undefined
  }

  show(title: string, translateParams?: unknown) {
    this.title = title
    this.translateParams = translateParams
    this.visible$.next(true)
    this.answer = new Subject<boolean>()
    return this.answer.asObservable()
  }

  back(force: boolean = false) {
    this.force = force
    return this.router.navigate(['/'])
  }

  exit() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: {
        exit: true
      }
    })
  }

  stay() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: null
    })
  }
}
