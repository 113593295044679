import { Sound } from "./sound.service";

export class BufferLoader {

  constructor(private context: AudioContext) { }

  public loadBuffer(name: Sound): Promise<AudioBuffer> {
    const url = `assets/audio/${name}.mp3`;
    const loader = this;

    return new Promise((resolve, reject) => {
      // Load buffer asynchronously
      const request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "arraybuffer";

      request.onload = function () {
        // Asynchronously decode the audio file data in request.response
        loader.context.decodeAudioData(
          request.response,
          function (buffer) {
            if (!buffer) {
              reject(new Error(`error decoding file data: ${url}`));
              return;
            }
            resolve(buffer);
          },
          function (error) {
            console.error('decodeAudioData error', error);
          }
        );
      };

      request.onerror = function () {
        reject(new Error('BufferLoader: XHR error'));
      };

      request.send();
    });
  }

  public async load(sounds: Sound[]): Promise<Map<Sound, AudioBuffer>> {
    const loading = sounds.map<Promise<[Sound, AudioBuffer]>>(async (sound) => [sound, await this.loadBuffer(sound)]);
    const map = new Map<Sound, AudioBuffer>(await Promise.all(loading));
    return map;
  }
}
