<div (click)="media.toggleMute()" class="icon">
  <svg *ngIf="!media.mute else mute" height="24" version="1.0" viewBox="0 0 75 75" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z"/>
    <path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6"/>
  </svg>
  <ng-template #mute>
    <svg height="24" stroke="#8C8E9B" stroke-width="5" version="1.0" viewBox="0 0 75 75"
         width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="m39,14-17,15H6V48H22l17,15z" fill="#8C8E9B" stroke-linejoin="round"/>
      <path d="m49,26 20,24m0-24-20,24" fill="none" stroke-linecap="round"/>
    </svg>
  </ng-template>
</div>
