<div *ngIf="result.visible$ | async" class="popup">
  <ng-container *ngIf="timer.timers.total as total">
    <ng-container *ngIf="timer.timers.limit as limit">
      <div [class.success]="result.success || total.done"
           class="popup-content">
        <div class="filler"></div>

        <div class="popup-message happy">
          <div class="title">
            <b *ngIf="total.done">{{'result.nice' | translate}}<br></b>
            {{'result.target' | translate}} <strong>{{total.percent}}%</strong> {{'result.reach' | translate}}
            <br>
            <b>
              ({{ limit.stopWatch$ | async }} {{'result.of' | translate}} {{total.max | toMinutes | date:'m'}} {{'result.min' | translate}})
            </b>
            <br>
            <i *ngIf="total.percent < 100">
              {{'result.question' | translate}}
            </i>
          </div>
          <ng-content></ng-content>

          <svg height="22" width="30">
            <polygon fill="#FFFFFF" points="3,0 27,0 15,16" stroke="#FFFFFF" stroke-linejoin="round" stroke-width="5"/>
          </svg>
        </div>

        <div class="filler"></div>

        <div class="actions">
          <button (click)="result.hide(false)"
                  *ngIf="!limit.done"
                  class="{{total.done ? 'decline' : 'accept'}}">
            {{'button.action.continue' | translate}}
          </button>
          <button (click)="result.hide(true)"
                  *ngIf="!result.manualExit && !limit.done"
                  class="{{total.done ? 'decline' : 'accept'}}">
            {{'button.action.another' | translate}}
          </button>
          <button (click)="exit()"
                  class="{{!total.done ? 'decline' : 'accept'}}">
            {{'button.action.exit' | translate}}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

</div>



