import { Component, OnInit } from '@angular/core'
import { ResultService } from '@services/result.service'
import { TimerService } from '@services/timer.service'

@Component({
  selector: 'inka-result-popup',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  constructor(public result: ResultService,
              public timer: TimerService) {
  }

  ngOnInit(): void {
  }

  exit() {
    this.timer.leave()
  }
}
